import { DialogTitle } from '@headlessui/react';
import PropTypes from 'prop-types';
import { CheckboxInput } from '../../../FormElements';
import Modal from '../../../Modal/Modal';
import AccountAddressModalWrapper from './AccountAddressModalWrapper';

function AccountAddressModal({
  open,
  handleCloseModal,
  currentAddress = null,
  accountId,
}) {
  return (
    <Modal open={open} setOpen={handleCloseModal} size="2xl" zdex={999}>
      <div>
        <div className="text-center">
          <DialogTitle
            as="h2"
            className="font-semibold text-2xl text-rcgray-900 capitalize leading-6"
          >
            {currentAddress ? 'Update' : 'New'} shipping address
          </DialogTitle>
        </div>
      </div>
      <div className="mt-4">
        <AccountAddressModalWrapper
          accountAddress={currentAddress}
          accountId={accountId}
          handleCloseModal={handleCloseModal}
        >
          <div className="sm:col-span-6">
            <div className="mt-4 sm:col-span-full">
              <CheckboxInput
                name="isDefault"
                label="Default?"
                description="Make this the default shipping address?"
              />
            </div>
          </div>
        </AccountAddressModalWrapper>
      </div>
    </Modal>
  );
}

AccountAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  currentAddress: PropTypes.object,
  accountId: PropTypes.string.isRequired,
};

export default AccountAddressModal;
