import { useApolloClient } from '@apollo/client';
import { ArchiveBoxXMarkIcon } from '@heroicons/react/20/solid';
import { ACTION_ORDER_STATUS } from '@retainerclub/admin-api';
import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { SelectMenuItem } from '../../../shared';

function OrderShowActions({ order }) {
  const client = useApolloClient();

  const { isAdmin } = useApp();

  async function changeState(action) {
    await client.mutate({
      mutation: ACTION_ORDER_STATUS,
      variables: {
        input: {
          input: {
            id: order.id,
            action,
          },
          clientMutationId: crypto.randomUUID(),
        },
      },
    });
  }

  return isAdmin &&
    (order.state === 'pending' || order.state === 'complete') ? (
    <SelectMenuItem
      onClick={() => changeState('deliver')}
      icon={ArchiveBoxXMarkIcon}
    >
      Mark as Delivered
    </SelectMenuItem>
  ) : null;
}

OrderShowActions.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShowActions;
