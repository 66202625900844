import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function NavigationDesktopDropdown(props) {
  const { menuItems, title } = props;

  const { t } = useTranslation('navigation');
  const { isCustomer } = useApp();

  return (
    <Popover className="relative">
      <PopoverButton className="inline-flex items-center gap-x-1 rounded-md p-2 font-medium text-black text-sm outline outline-2 outline-white/0 outline-offset-0 hover:bg-rcgray-200/20 hover:bg-rcprimary-400/0 hover:text-rcprimary-200 hover:text-rcprimary-400 hover:outline-rcprimary-400">
        <span>{t(title)}</span>
        <ChevronDownIcon className="size-5" aria-hidden="true" />
      </PopoverButton>

      <Transition
        as="div"
        className="-translate-x-1/4 absolute left-1/2 z-10 mt-2 flex w-screen max-w-max px-4 transition duration-200 ease-out data-[closed]:translate-y-1 data-[enter]:translate-y-0 data-[leave]:translate-y-1 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
      >
        <PopoverPanel className="-translate-x-1/4 absolute left-1/2 z-10 mt-2 flex w-screen max-w-max px-4">
          <div
            className={`w-screen max-w-md flex-auto overflow-hidden rounded-3xl border border-rcprimary-100/30 ${isCustomer ? 'bg-rcgray-50' : 'bg-black/90'} text-sm leading-6 shadow-lg ring-1 ring-gray-900/5`}
          >
            {menuItems.map((item) => (
              <div
                key={item.name}
                className={`${isCustomer ? 'hover:bg-rcprimary-400/20' : 'hover:bg-rcprimary-400'} group relative flex gap-x-6 p-4`}
              >
                <div
                  className={`mt-1 flex size-11 flex-none items-center justify-center rounded-lg bg-rcprimary-400 ${isCustomer ? 'group-hover:border group-hover:border-rcprimary-400 group-hover:bg-transparent' : 'group-hover:bg-black'}`}
                >
                  <item.icon
                    className="size-6 text-black group-hover:text-rcprimary-400"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <PopoverButton
                    as={NavLink}
                    to={item.to}
                    className={`font-semibold ${isCustomer ? 'text-black/80' : 'text-white'} group-hover:text-black`}
                  >
                    {t(item.name)}
                    <span className="absolute inset-0" />
                  </PopoverButton>
                  <p
                    className={`mt-1 ${isCustomer ? 'text-black/70' : 'text-white/70'} group-hover:text-black`}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}

NavigationDesktopDropdown.propTypes = {
  menuItems: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavigationDesktopDropdown;
