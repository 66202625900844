import { useApp } from '@retainerclub/hooks';

function DesktopNavUnauthed() {
  const { isCustomer } = useApp();

  return isCustomer ? null : (
    <div className="hidden lg:flex lg:gap-x-12">
      <a
        href="https://v2.retainerclub.com"
        className="font-normal text-gray-900 text-sm leading-6 hover:text-rcprimary-400 hover:underline"
      >
        Home
      </a>
      <a
        href="https://locator.retainerclub.com"
        className="font-normal text-gray-900 text-sm leading-6 hover:text-rcprimary-400 hover:underline"
      >
        Doctor Locator
      </a>
      <a
        href="https://v2.retainerclub.com/why-replace-retainers"
        className="font-normal text-gray-900 text-sm leading-6 hover:text-rcprimary-400 hover:underline"
      >
        Why Replace Retainers?
      </a>
      <a
        href="https://v2.retainerclub.com/support"
        className="font-normal text-gray-900 text-sm leading-6 hover:text-rcprimary-400 hover:underline"
      >
        Support
      </a>
    </div>
  );
}

export default DesktopNavUnauthed;
