import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import Modal from '../../../../../shared/Modal/Modal';
import ArchSelection from './ArchSelection';
import EotCheckbox from './EotCheckbox';

function RetainerSelectionModal({
  open,
  setOpen,
  archSelection,
  setArchSelection,
  setCheckboxChange,
  checkboxChange,
  handleSelection,
}) {
  return open ? (
    <Modal open={open} setOpen={setOpen} size="xl">
      <div>
        <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-rcprimary-100">
          <ExclamationTriangleIcon
            className="size-6 text-rcprimary-400"
            aria-hidden="true"
          />
        </div>
        <div className="mt-2 text-center">
          <Dialog.Title
            as="h2"
            className="font-semibold text-2xl text-gray-900 leading-6"
          >
            Choose your product
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-gray-500 text-sm">
              Select the retainer arches you want to order.
            </p>
            <div className="mt-8 flex flex-col sm:flex-row sm:items-center">
              <div className="sm:basis-1/3">
                <button
                  type="button"
                  onClick={() =>
                    setArchSelection({
                      ...archSelection,
                      upper: !archSelection.upper,
                    })
                  }
                >
                  <span className="sr-only">Select Arch</span>
                  <ArchSelection
                    className="rotate-180"
                    selected={archSelection.upper}
                  />
                </button>

                <button
                  type="button"
                  onClick={() =>
                    setArchSelection({
                      ...archSelection,
                      lower: !archSelection.lower,
                    })
                  }
                >
                  <span className="sr-only">Select Arch</span>
                  <ArchSelection selected={archSelection.lower} />
                </button>
              </div>

              <div className="sm:basis-2/3">
                <p className="font-bold text-lg">
                  {archSelection.upper && archSelection.lower && 'Both arches '}
                  {archSelection.upper && !archSelection.lower && 'Upper arch '}
                  {!archSelection.upper && archSelection.lower && 'Lower arch '}
                  {!(archSelection.upper || archSelection.lower) &&
                    'No arches '}
                  selected
                </p>
                {!(archSelection.upper || archSelection.lower) && (
                  <p className="text-gray-500 text-xs">
                    (Please select at least one arch)
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <EotCheckbox
          setCheckboxChange={setCheckboxChange}
          checkboxChange={checkboxChange}
        />
      </div>
      <div className="mt-4">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-rcprimary-400 px-3 py-2 font-semibold text-black text-sm shadow-sm hover:bg-rcprimary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-rcprimary-400 focus-visible:outline-offset-2 disabled:cursor-not-allowed disabled:bg-rcgray-400 disabled:focus-visible:outline-2 disabled:focus-visible:outline-rcgray-400 disabled:focus-visible:outline-offset-2 disabled:hover:bg-rcgray-400"
          onClick={() => handleSelection()}
          disabled={!(archSelection.upper || archSelection.lower)}
        >
          Make Selection
        </button>
      </div>
    </Modal>
  ) : null;
}

RetainerSelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  archSelection: PropTypes.shape({
    upper: PropTypes.bool,
    lower: PropTypes.bool,
  }).isRequired,
  setArchSelection: PropTypes.func.isRequired,
  setCheckboxChange: PropTypes.func.isRequired,
  checkboxChange: PropTypes.bool.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

export default RetainerSelectionModal;
