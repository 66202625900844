import { useQuery } from '@apollo/client';
import { useFormValues } from '@retainerclub/hooks';
import { GET_ROLE_TYPES } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldContainer } from '../FieldContainer';

function RoleSelect({
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  modelScope,
  setRole = null,
}) {
  const { t } = useTranslation('forms');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { onChange, onBlur, ref, name } = register('roleTypeId');

  const { roleTypeId } = useFormValues();

  const { error, loading, data } = useQuery(GET_ROLE_TYPES, {
    variables: { modelScope },
  });

  const handleChange = (e) => {
    onChange(e);
  };

  useEffect(() => {
    if (roleTypeId && setRole && data?.roleTypes) {
      setRole(data.roleTypes.find((roleType) => roleType.id === roleTypeId));
    }
  }, [data?.roleTypes, roleTypeId, setRole]);

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label="Role"
    >
      {error || loading ? (
        <div>loading</div>
      ) : (
        <select
          ref={ref}
          onBlur={onBlur}
          name={name}
          onChange={handleChange}
          className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
            errors?.[name]
              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
              : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
          }`}
          disabled={data?.roleTypes === undefined || data?.roleTypes === null}
        >
          <option>{`${t('Select a Role')}...`}</option>
          {data?.roleTypes.map((roleType) => (
            <option key={roleType.id} value={roleType.id}>
              {roleType.name}
            </option>
          ))}
        </select>
      )}
    </FieldContainer>
  );
}

RoleSelect.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  modelScope: PropTypes.string.isRequired,
  setRole: PropTypes.func,
};

export default RoleSelect;
