import { useQuery } from '@apollo/client';
import { GET_REGIONS } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldContainer } from '../FieldContainer';

function RegionSelect({
  name: n = 'address.region',
  countryFieldName = 'address.country',
  style = {},
  className = '',
  required = false,
  hideLabel = false,
  autoComplete = 'address-level1',
}) {
  const { t } = useTranslation('forms');
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { onChange, onBlur, ref, name } = register(n);
  const country = useWatch({ name: countryFieldName });

  const handleChange = (e) => {
    onChange(e);
  };

  const { error, loading, data } = useQuery(GET_REGIONS, {
    variables: { country },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label="State / Region"
    >
      {error || loading ? (
        <div>loading</div>
      ) : (
        <select
          ref={ref}
          onBlur={onBlur}
          name={name}
          onChange={handleChange}
          autoComplete={autoComplete}
          className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
            errors?.[name]
              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
              : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
          }`}
          disabled={data?.regions === undefined || data?.regions === null}
        >
          <option disabled>{`${t('Select a State / Region')}...`}</option>
          {data?.regions?.map((region) => (
            <option key={region.id} value={region.value}>
              {region.name}
            </option>
          ))}
        </select>
      )}
    </FieldContainer>
  );
}

RegionSelect.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  countryFieldName: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default RegionSelect;
