import * as Yup from 'yup';

const YEAR_REGEX = /(19|20)\d{2}/;

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    accountId: Yup.string().nullable(true),
    firstName: Yup.string().required('first name is required'),
    lastName: Yup.string().required('last name is required'),
    birthDay: Yup.string().required('day of birth is required'),
    birthMonth: Yup.string().required('birth month is required'),
    birthYear: Yup.string()
      .required('birth year is required')
      .matches(YEAR_REGEX, 'year must be between 1900 and 2099'),
  });
}

export default schema;
