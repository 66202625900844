/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

const sortOptionsDefaults = [
  { name: 'Most Popular', href: '#', current: true },
  { name: 'Name A-Z', href: '#', current: false },
  { name: 'Name Z-A', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
];

function ShopSort({ filteredProducts = [], setFilteredProducts }) {
  const [sortOptions, setSortOptions] = useState(sortOptionsDefaults);

  const retainers = useMemo(
    () =>
      filteredProducts.filter((p) =>
        p.product.productCategories.some((c) => c.name === 'Retainers'),
      ),
    [filteredProducts],
  );

  function handleSortChange(option) {
    setSortOptions(
      sortOptions.map((sortOption) => ({
        ...sortOption,
        current: sortOption.name === option.name,
      })),
    );

    switch (option.name) {
      case 'Most Popular':
        setFilteredProducts([
          ...filteredProducts.filter((p) => p.product.productType === 'plan'),
          ...retainers,
          ...filteredProducts
            .filter(
              (p) => p.product.productType !== 'plan' && !retainers.includes(p),
            )
            .sort((a, b) => a.product.name.localeCompare(b.product.name)),
        ]);
        break;
      case 'Name A-Z':
        setFilteredProducts([
          ...filteredProducts.sort((a, b) =>
            a.product.name.localeCompare(b.product.name),
          ),
        ]);
        break;
      case 'Name Z-A':
        setFilteredProducts([
          ...filteredProducts.sort((a, b) =>
            b.product.name.localeCompare(a.product.name),
          ),
        ]);
        break;
      case 'Price: Low to High':
        setFilteredProducts([
          ...filteredProducts.sort((a, b) => a.price - b.price),
        ]);
        break;
      case 'Price: High to Low':
        setFilteredProducts([
          ...filteredProducts.sort((a, b) => b.price - a.price),
        ]);
        break;
      default:
        break;
    }
  }

  return (
    <div className="col-span-1 py-2 sm:py-4">
      <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
        <Menu as="div" className="relative inline-block">
          <div className="flex">
            <MenuButton className="group inline-flex justify-center font-medium text-gray-700 text-sm hover:text-gray-900">
              Sort
              <ChevronDownIcon
                className="-mr-1 ml-1 size-5 shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </MenuButton>
          </div>

          <Transition
            as="div"
            className="absolute right-0 z-20 mt-2 w-52 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black/5 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[enter]:scale-100 data-[leave]:scale-95 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
          >
            <MenuItems className="absolute right-0 z-20 mt-2 w-52 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black/5 focus:outline-none">
              <div className="py-1">
                {sortOptions.map((option) => (
                  <MenuItem key={option.name}>
                    {({ active }) => (
                      <button
                        onClick={() => handleSortChange(option)}
                        type="button"
                        className={`${
                          option.current
                            ? 'font-medium text-gray-900 '
                            : 'text-gray-500 '
                        } ${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-sm`}
                      >
                        {option.name}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

ShopSort.propTypes = {
  filteredProducts: PropTypes.array,
  setFilteredProducts: PropTypes.func.isRequired,
};

export default ShopSort;
