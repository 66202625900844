import { gql } from '@apollo/client';

export default gql`
  query countries($auOnly: Boolean) {
    countries(auOnly: $auOnly) {
      id
      value
      name
    }
  }
`;
