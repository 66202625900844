/* eslint-disable react/jsx-no-bind */
import { useQuery } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { GET_PAID_IN_OFFICE } from '@retainerclub/partner-api';
import PropTypes from 'prop-types';
import {
  CheckboxInput,
  DateInput,
  Fieldset,
  Input,
  LoadingOrError,
  Modal,
  TextArea,
} from '../../../../shared';
import CaseSettingsFormFields from '../../../CaseSettings/CaseSettingsFormFields';
import {
  ReferralsFormPatientScanReady,
  ReferralsFormScanOptions,
} from '../../ReferralsForm';
import AdditionalRetainerSelection from './ProductSelections/AdditionalRetainerSelection';
import AdditionalSmileCarePlanSelection from './ProductSelections/AdditionalSmileCarePlanSelection';
import ReferralsProductionListModalFormInner from './ReferralsProductionListModalFormInner';

function ReferralsProductionListModalForm({
  handleCloseModal,
  currentRecord = null,
  open,
}) {
  const { data, error, loading } = useQuery(GET_PAID_IN_OFFICE, {
    variables: {
      partnerLocationId: currentRecord?.partnerLocationId,
    },
    skip: !(currentRecord?.partnerLocationId && open),
  });

  return (
    <Modal open={open} setOpen={handleCloseModal} size="6xl">
      <div>
        <div className="text-center">
          <Dialog.Title
            as="h2"
            className="font-semibold text-2xl text-gray-900 leading-6"
          >
            Update case details for&nbsp;
            <span className="capitalize">
              {currentRecord?.firstName} {currentRecord?.lastName}
            </span>
          </Dialog.Title>
        </div>
      </div>
      <div className="mt-4">
        {currentRecord && (
          <ReferralsProductionListModalFormInner
            record={currentRecord}
            handleCloseModal={handleCloseModal}
          >
            <Fieldset
              title="Patient Info"
              description="Who is receiving treatment?"
              doubleCols
            >
              <Input
                className="col-span-full sm:col-span-6 xl:col-span-4"
                required
                name="firstName"
                label="Patient First Name"
              />
              <Input
                className="col-span-full sm:col-span-6 xl:col-span-4"
                required
                name="lastName"
                label="Patient Last Name"
              />
              <DateInput
                dayName="birthDay"
                monthName="birthMonth"
                yearName="birthYear"
                required
                wrapperClass="col-span-full sm:col-span-6 xl:col-span-4"
              />
            </Fieldset>
            <Fieldset
              title="Scan Details"
              description="What is the status of the patient's scan?"
            >
              <div className="col-span-full mt-2 sm:col-span-4">
                <ReferralsFormScanOptions
                  name="scanReady"
                  usePatientAttributes={false}
                />
              </div>
              <div className="col-span-full mt-2 grid grid-cols-1 sm:col-span-2 sm:grid-cols-2">
                <ReferralsFormPatientScanReady
                  usePatientAttributes={false}
                  scanReadyAt="scanReadyAt"
                  scanSentAt="scanSentAt"
                />
              </div>
            </Fieldset>

            {(error || loading) && (
              <div className="h-[250px] w-full">
                <LoadingOrError loading={loading} error={error} />
              </div>
            )}

            {data?.paidInOffice && (
              <Fieldset
                title="Paid in office options"
                description="Would you like to add addiitonal products for the patient?"
                className="border-b-0 pb-0"
              >
                <div className="col-span-full mt-4 flex flex-row gap-4 sm:col-span-4">
                  <AdditionalRetainerSelection
                    partnerLocationId={currentRecord?.partnerLocationId}
                    patientId={currentRecord?.id}
                  />
                  <AdditionalSmileCarePlanSelection
                    partnerLocationId={currentRecord?.partnerLocationId}
                    patientId={currentRecord?.id}
                    hasPlan={currentRecord?.hasPlan}
                  />
                </div>
              </Fieldset>
            )}

            <div className="col-span-full mt-4">
              <CaseSettingsFormFields />
            </div>
            <div className="col-span-full">
              <TextArea name="notes" label="Additional Notes" />
            </div>
            <div className="col-span-full mt-4 sm:col-span-3">
              <CheckboxInput
                name="sendToClinic"
                label="Ship to Clinic"
                required
                size="lg"
                description="Select to ship the first order to the clinic."
              />
            </div>
          </ReferralsProductionListModalFormInner>
        )}
      </div>
    </Modal>
  );
}

ReferralsProductionListModalForm.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  currentRecord: PropTypes.object,
  open: PropTypes.bool.isRequired,
};

export default ReferralsProductionListModalForm;
