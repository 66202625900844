import { TabPanels } from '@headlessui/react';
import PropTypes from 'prop-types';

function RcTabPanels({ children, height = 'md' }) {
  return (
    <TabPanels
      as="div"
      className={`${
        height === 'md' ? 'min-h-[500px]' : 'min-h-[300px]'
      } bg-white/80 p-4 shadow sm:rounded-r-lg sm:rounded-b-lg sm:px-4 sm:pb-4`}
    >
      {children}
    </TabPanels>
  );
}

RcTabPanels.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
};

export default RcTabPanels;
