import { useQuery } from '@apollo/client';
import { GET_PARTNER_LOC, GET_REFERRAL } from '@retainerclub/customer-api';
import { createContext, useContext, useMemo } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useCart } from './useCart';

const ReferralContext = createContext();

export function ReferralProvider() {
  const { updateCartLocal } = useCart();

  const [urlSearchParams] = useSearchParams();
  const rId = urlSearchParams.get('rId');
  const pId = urlSearchParams.get('pId');

  const {
    data,
    refetch,
    loading: rLoading,
    error: rError,
  } = useQuery(GET_REFERRAL, {
    variables: { id: rId },
    skip: rId === null || rId === undefined,
    onCompleted: (d) => {
      if (d?.referral?.cart?.id) {
        updateCartLocal(d.referral.cart);
      }
    },
  });

  const {
    data: pData,
    loading: pLoading,
    error: pError,
  } = useQuery(GET_PARTNER_LOC, {
    variables: { id: pId },
    skip: pId === null || pId === undefined,
  });

  const value = useMemo(
    () => ({
      referral: data?.referral,
      partnerLocation: pData?.partnerLocation,
      refetch,
      rLoading,
      rError,
      pLoading,
      pError,
    }),
    [
      data?.referral,
      pData?.partnerLocation,
      pError,
      pLoading,
      rError,
      rLoading,
      refetch,
    ],
  );

  return (
    <ReferralContext.Provider value={value}>
      <Outlet />
    </ReferralContext.Provider>
  );
}

export function useReferral() {
  return useContext(ReferralContext);
}
