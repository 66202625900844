import { useMutation } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { timestampFormatter } from '@retainerclub/common';
import { useLocale } from '@retainerclub/hooks';
import { CREATE_RETAINER_BANK_ORDER } from '@retainerclub/shared-api';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

function RetainerWizardFromBank({ patient, handleCloseModal }) {
  const { locale } = useLocale();
  const [create] = useMutation(CREATE_RETAINER_BANK_ORDER);
  const [success, setSuccess] = useState(false);

  const scheduledShipments = useMemo(
    () =>
      patient.scheduledShipments
        .sort(
          (a, b) =>
            new Date(b.expectedShippedAt) - new Date(a.expectedShippedAt),
        )
        .filter((shipment) => !shipment.consumed),
    [patient.scheduledShipments],
  );

  async function submitRecord() {
    try {
      const params = {
        variables: {
          input: {
            input: { patientId: patient.id },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data } = await create(params);

      const mErrors = data.createRetainerBankOrder.errorMessages;

      if (mErrors.length > 0) {
        // eslint-disable-next-line no-console
        console.error(mErrors);
      } else {
        setSuccess(true);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  return (
    <div className="isolate overflow-hidden">
      <div className="flow-root bg-gray-900 pt-8 pb-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="relative z-10">
            <h2 className="mx-auto max-w-4xl text-center font-bold text-5xl text-white tracking-tight">
              Right Retainer. Right Time
              <span className="align-super text-xl">&#8482;</span>
            </h2>
            <p className="mx-auto mt-4 max-w-2xl text-center text-lg text-white/60 leading-8">
              {patient.firstName} has {patient.retainerBank} retainer
              {patient.retainerBank > 1 ? 's' : ''} available
            </p>
          </div>
          <div className="lg:-mb-14 relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <svg
              viewBox="0 0 1208 1024"
              aria-hidden="true"
              className="-bottom-48 -translate-x-1/2 lg:-top-48 absolute left-1/2 h-[64rem] translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:bottom-auto lg:translate-y-0"
            >
              <ellipse
                cx={604}
                cy={512}
                fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
                rx={604}
                ry={512}
              />
              <defs>
                <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                  <stop stopColor="#37b87b" />
                  <stop offset={1} stopColor="#d4f7e1" />
                </radialGradient>
              </defs>
            </svg>
            <div
              className="hidden lg:absolute lg:inset-x-px lg:top-4 lg:bottom-0 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
              aria-hidden="true"
            />
            <div className="relative left-full z-10 rounded-2xl bg-white shadow-xl ring-1 ring-gray-900/10">
              <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                <h3 className="font-semibold text-rcgray-900 text-sm leading-6">
                  Retainer
                </h3>
                <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                  <div className="mt-2 flex items-center gap-x-4">
                    <p className="font-bold text-4xl text-rcgray-900 tracking-tight">
                      {(0).toLocaleString(locale, {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </p>
                    <div className="text-sm leading-5">
                      <p className="text-gray-500">INCLUDED</p>
                    </div>
                  </div>
                  {success ? (
                    <button
                      type="button"
                      className="rounded-md bg-rcprimary-400 px-3 py-2 text-center font-semibold text-sm text-white leading-6 shadow-sm hover:bg-rcprimary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-rcprimary-400 focus-visible:outline-offset-2"
                      onClick={() => handleCloseModal()}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="rounded-md bg-rcprimary-400 px-3 py-2 text-center font-semibold text-sm text-white leading-6 shadow-sm hover:bg-rcprimary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-rcprimary-400 focus-visible:outline-offset-2"
                      onClick={() => submitRecord()}
                    >
                      Ship Next Retainer Now
                    </button>
                  )}
                </div>
                <div className="mt-8 flow-root sm:mt-10">
                  <div>
                    <p className="text-gray-600 text-sm leading-5">
                      The next scheduled shipment is&nbsp;
                      {scheduledShipments.length > 0
                        ? timestampFormatter(
                            scheduledShipments[0].expectedShippedAt,
                            false,
                          )
                        : 'N/A'}
                    </p>
                    {success ? (
                      <div className="my-2 rounded-md bg-green-50 p-4">
                        <div className="flex">
                          <div className="shrink-0">
                            <CheckCircleIcon
                              className="size-5 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="font-medium text-green-800 text-sm">
                              Order completed
                            </h3>
                            <div className="mt-2 text-green-700 text-sm">
                              <p>
                                Your next retainer is being made and will be on
                                its way shortly!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="mt-2 font-medium">
                        Click the button above to start production of your next
                        set of retainers.
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <p className="mt-4 text-xs">
                    This will reduce your number of retainer sets available by 1
                    leaving you with {patient.retainerBank - 1} in your bank
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RetainerWizardFromBank.propTypes = {
  patient: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default RetainerWizardFromBank;
