import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { useToast } from '@retainerclub/hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function Toast() {
  const {
    toast: { title, message, show, success },
    setToast,
  } = useToast();

  const { t } = useTranslation('ui');
  const { state } = useLocation();

  useEffect(() => {
    if (state?.showToast) {
      setToast({
        show: true,
        message: state.message,
        title: state.title,
        success: true,
      });
    }
  }, [state?.message, state?.showToast, state?.title, setToast]);

  const dismissToast = useCallback(() => {
    setToast({
      show: false,
      message: '',
      title: '',
      success: true,
    });
  }, [setToast]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!show) {
        return;
      }

      dismissToast();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [dismissToast, show]);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-[999] flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition show={show}>
          <div
            className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg ${success ? 'bg-white' : 'bg-red-50'} shadow-lg ring-1 ring-black/5 transition duration-300 ease-in-out data-[closed]:translate-y-2 data-[enter]:translate-y-0 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0 data-[closed]:sm:translate-x-2 data-[enter]:sm:translate-x-0 data-[closed]:sm:translate-y-0 `}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  {success ? (
                    <CheckCircleIcon
                      className="size-6 text-green-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <ExclamationTriangleIcon
                      className="size-6 text-rccallouts-danger"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="font-medium text-gray-900 text-sm">
                    {t(title)}
                  </p>
                  <p className="mt-1 text-gray-500 text-sm">{t(message)}</p>
                </div>
                <div className="ml-4 flex shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      dismissToast();
                    }}
                  >
                    <span className="sr-only">{t('Close')}</span>
                    <XMarkIcon className="size-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default Toast;
