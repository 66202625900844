/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

function ShowTableStandard({ tHead, tBody, light = false }) {
  return (
    <div className="mt-4 flow-root ">
      <div className="overflow-x-auto ring-1 ring-black/10 sm:rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <div
            className={`overflow-hidden shadow sm:rounded-lg ${
              light ? 'bg-white/90' : ''
            }`}
          >
            <table className="min-w-full table-auto divide-y divide-black/20 text-sm">
              <thead
                className={`font-normal text-xs ${
                  light
                    ? 'bg-rcgray-200/50 text-black/90'
                    : 'bg-rcgray-200/80 text-black'
                }`}
              >
                <tr className="divide-x divide-black/20 text-left">{tHead}</tr>
              </thead>
              <tbody className="divide-y divide-black/20">{tBody}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

ShowTableStandard.propTypes = {
  tHead: PropTypes.node.isRequired,
  tBody: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  light: PropTypes.bool,
};

export default ShowTableStandard;
