import { AddressAutofill } from '@mapbox/search-js-react';
import { CountrySelect, Input, RegionSelect } from '@retainerclub/common';
import { useFormContext } from 'react-hook-form';
import CheckoutFormSection from './CheckoutFormSection';

function CheckoutFormAddress() {
  const { setValue } = useFormContext();

  return (
    <CheckoutFormSection title="Shipping Information">
      <div className="sm:col-span-6">
        <AddressAutofill
          accessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          confirmOnBrowserAutofill={{
            minimap: true,
            skipConfirmModal: (feature) =>
              ['exact', 'high'].includes(
                feature.properties.match_code.confidence,
              ),
          }}
          onRetrieve={(address) => {
            if (address?.features?.length > 0) {
              const {
                country_code: countryCode,
                region_code: regionCode,
                matching_name: matchingName,
              } = address.features[0].properties;

              setValue('shippingAddress.country', countryCode.toUpperCase());

              setValue('shippingAddress.region', regionCode.toUpperCase());

              setValue('shippingAddress.address', matchingName);
            }
          }}
          onChange={(event) => {
            setValue('shippingAddress.address', event);
          }}
          options={{
            country: ['US'],
          }}
        >
          <Input
            name="shippingAddress.address"
            label="Address"
            required
            autoComplete=""
          />
        </AddressAutofill>
      </div>

      <div className="sm:col-span-3">
        <Input
          name="shippingAddress.address2"
          label="Address Line 2"
          autoComplete="address-line2"
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          name="shippingAddress.city"
          label="City"
          required
          autoComplete="address-level2"
        />
      </div>
      <div className="sm:col-span-2">
        <CountrySelect
          name="shippingAddress.country"
          required
          autoComplete=""
          usOnly
        />
      </div>
      <div className="sm:col-span-2">
        <RegionSelect
          name="shippingAddress.region"
          countryFieldName="shippingAddress.country"
          autoComplete=""
        />
      </div>
      <div className="sm:col-span-2">
        <Input
          name="shippingAddress.postalCode"
          label="Postal Code"
          required
          autoComplete="postal-code"
        />
      </div>
    </CheckoutFormSection>
  );
}

export default CheckoutFormAddress;
