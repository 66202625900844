import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import FieldContainer from '../FieldContainer/FieldContainer';

function TextArea({
  name,
  label = null,
  style = {},
  className = '',
  placeholder = '',
  required = false,
  hideLabel = false,
  rows = 4,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
      type="textarea"
    >
      <textarea
        {...register(name)}
        rows={rows}
        name={name}
        id={name}
        required={required}
        placeholder={placeholder}
        className={`block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-inset sm:py-1.5 sm:text-sm sm:leading-6 ${
          errors?.[name]
            ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
            : 'text-gray-900 placeholder:text-gray-400 focus:ring-rcprimary-400'
        }`}
      />
    </FieldContainer>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  rows: PropTypes.number,
};

export default TextArea;
