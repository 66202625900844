import { gql } from '@apollo/client';
import { ErrorMessageInfo } from '@retainerclub/shared-api';

export default gql`
  mutation actionLaxmiOrder($input: LaxmiOrderActionInput!) {
    actionLaxmiOrder(input: $input) {
      clientMutationId
      errorMessages {
        ...ErrorMessageInfo
      }
      success
    }
  }
  ${ErrorMessageInfo}
`;
