import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { CogIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import NavColumn from './NavColumn';

function NavigationDesktopDropdownFull(props) {
  const { sections } = props;

  return (
    <Popover className="relative">
      <div>
        <PopoverButton className="flex max-w-xs items-center rounded-full text-black text-sm hover:text-rcprimary-400 focus:outline-none">
          <CogIcon className="h-6 w-6" aria-hidden="true" />
        </PopoverButton>
      </div>

      <Transition
        as="div"
        className="data-[closed]:-translate-y-1 data-[leave]:-translate-y-1 transition duration-200 ease-out data-[enter]:translate-y-0 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
      >
        <PopoverPanel className="-z-10 fixed right-0 mx-4 mt-2">
          <div className="grid grid-cols-3 gap-x-6 rounded-3xl border border-rcprimary-100/30 bg-black/90 p-12 shadow-lg sm:gap-x-32">
            {sections.map((section) => (
              <NavColumn
                key={section.title}
                title={section.title}
                links={section.links}
              />
            ))}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}

NavigationDesktopDropdownFull.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default NavigationDesktopDropdownFull;
