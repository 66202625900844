import { Description, Label, Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useFormValues } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

const options = [
  {
    id: 1,
    title: 'Scan Available',
    description: 'Scan has been sent or will be sent today',
    value: true,
  },
  {
    id: 2,
    title: 'Awaiting Scan',
    description: 'The scan will be sent at a later date',
    value: false,
  },
];

function ReferralsFormScanOptions({
  name = 'patientAttributes.scanReady',
  usePatientAttributes = true,
}) {
  const { patientAttributes, scanReady } = useFormValues();
  const { setValue } = useFormContext();

  const formVal = useMemo(
    () => (usePatientAttributes ? patientAttributes?.scanReady : scanReady),
    [patientAttributes?.scanReady, scanReady, usePatientAttributes],
  );

  function handleScanReadyChange(value) {
    setValue(name, value);
  }

  return (
    <RadioGroup
      value={formVal}
      onChange={(value) => handleScanReadyChange(value)}
    >
      <Label className="block font-medium text-black/70 text-sm capitalize leading-6">
        Select a scan option
        <span className="text-red-400">*</span>
      </Label>

      <div className="mt-2 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
        {options.map((option) => (
          <Radio
            key={option.id}
            value={option.value}
            className={({ active }) =>
              `${
                active
                  ? 'border-rcprimary-400 ring-2 ring-rcprimary-400'
                  : 'border-rcgray-400'
              } relative flex cursor-pointer rounded-lg border bg-white p-6 shadow-sm focus:outline-none`
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <Label
                      as="span"
                      className="block font-medium text-gray-900 text-lg"
                    >
                      {option.title}
                    </Label>
                    <Description
                      as="span"
                      className="mt-1 flex items-center text-gray-500 text-sm"
                    >
                      {option.description}
                    </Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={`${!checked ? 'invisible' : ''} size-5 text-rcprimary-400`}
                  aria-hidden="true"
                />
                <span
                  className={`${active ? 'border ' : 'border-2 '}
                    ${checked ? 'border-rcprimary-400' : 'border-transparent'} -inset-px pointer-events-none absolute rounded-lg`}
                  aria-hidden="true"
                />
              </>
            )}
          </Radio>
        ))}
      </div>
    </RadioGroup>
  );
}

ReferralsFormScanOptions.propTypes = {
  name: PropTypes.string,
  usePatientAttributes: PropTypes.bool,
};

export default ReferralsFormScanOptions;
