import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import PropTypes from 'prop-types';

function Modal({
  open,
  setOpen,
  children,
  size = 'lg',
  panelClass = null,
  zdex = null,
}) {
  return (
    <Transition show={open}>
      <Dialog
        as="div"
        className={`relative ${zdex ? `z-[${zdex}]` : 'z-[9999]'}`}
        onClose={setOpen}
      >
        <TransitionChild
          as="div"
          className="fixed inset-0 bg-black/50 transition-opacity duration-300 ease-out data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild>
              <DialogPanel
                className={`${
                  panelClass ||
                  'relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition transition-all duration-300 ease-out data-[closed]:translate-y-4 data-[enter]:translate-y-0 data-[leave]:translate-y-4 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0 sm:my-8 sm:w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[leave]:sm:translate-y-0 data-[closed]:sm:scale-95 data-[enter]:sm:scale-100 data-[leave]:sm:scale-95'
                } ${size === 'lg' && 'sm:max-w-lg '} ${
                  size === 'xl' && 'sm:max-w-xl '
                } ${size === '2xl' && 'sm:max-w-2xl '} ${
                  size === '3xl' && 'sm:max-w-3xl '
                } ${size === '4xl' && 'sm:max-w-4xl '} ${
                  size === '5xl' && 'sm:max-w-5xl '
                } ${size === '6xl' && 'sm:max-w-6xl '} ${
                  size === '7xl' && 'sm:max-w-7xl '
                } ${size === 'full' && 'sm:max-w-full '}`}
              >
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  panelClass: PropTypes.string,
  size: PropTypes.oneOf([
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    'full',
  ]),
  zdex: PropTypes.number,
};

export default Modal;
