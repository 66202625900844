import { useQuery } from '@apollo/client';
import {
  LoadingOrError,
  ShowStatus,
  timestampFormatter,
} from '@retainerclub/common';
import { GET_ORDERS } from '@retainerclub/customer-api';
import { CustomerCard } from '@retainerclub/customer-shared';
import { useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import OrdersHistoryNoOrders from './OrdersHistoryNoOrders';
import OrdersHistoryOrderItem from './OrdersHistoryOrderItem';

function OrdersHistory({ limit = null }) {
  const { locale } = useLocale();
  const [orders, setOrders] = useState([]);

  const { error, loading } = useQuery(GET_ORDERS, {
    onCompleted: (data) => {
      setOrders(
        data.orders.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        ),
      );
    },
  });

  const ordersToDisplay = useMemo(() => {
    if (limit) {
      return orders.slice(0, 3);
    }

    return orders;
  }, [limit, orders]);

  if (loading || error) {
    return <LoadingOrError loading={loading} error={error} />;
  }

  function scanAvailableBadge(orderItems) {
    return orderItems.every((e) => e.patient.scanReady);
  }

  return ordersToDisplay.length > 0 ? (
    <CustomerCard title={limit ? 'My Recent Orders' : 'All Order History'}>
      <div className="space-y-16 sm:space-y-24">
        {ordersToDisplay.map((order) => (
          <div key={order.id}>
            <div className="bg-rcgray-200 px-4 py-6 sm:rounded-lg sm:p-6 md:flex md:items-center md:justify-between md:space-x-4">
              <dl className="flex-auto space-y-4 divide-y divide-gray-200 text-gray-600 text-sm md:grid md:grid-cols-4 md:gap-x-4 md:space-y-0 md:divide-y-0 lg:flex-none lg:gap-x-4">
                <div className="flex justify-between md:block">
                  <dt className="font-medium text-gray-900">Order number</dt>
                  <dd className="md:mt-1">{order.hexCode}</dd>
                </div>
                <div className="flex justify-between pt-4 md:block md:pt-0">
                  <dt className="font-medium text-gray-900">Date placed</dt>
                  <dd className="md:mt-1">
                    <time dateTime={order.createdAt}>
                      {timestampFormatter(order.createdAt)}
                    </time>
                  </dd>
                </div>

                <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                  <dt>Status</dt>
                  <dd className="md:mt-1">
                    <ShowStatus
                      state={order.state}
                      scanAvailable={scanAvailableBadge(order.orderItems)}
                    />
                  </dd>
                </div>
                <div className="flex justify-between pt-4 font-medium text-gray-900 md:block md:pt-0">
                  <dt>Total amount</dt>
                  <dd className="md:mt-1">
                    {(order.total / 100).toLocaleString(locale, {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </dd>
                </div>
              </dl>
              {/*
              <div className="mt-6 space-y-4 sm:flex sm:space-x-4 sm:space-y-0 md:mt-0">
                <a
                  href={order.invoiceHref}
                  className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:w-auto"
                >
                  View Receipt
                </a>
              </div>
                  */}
            </div>

            <div className="mt-4 flow-root px-4 sm:px-0">
              <div className="-my-4 sm:-my-8 divide-y divide-gray-200">
                {order.orderItems.map((orderItem) => (
                  <OrdersHistoryOrderItem
                    key={orderItem.id}
                    orderItem={orderItem}
                    productionOrders={order.productionOrders}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </CustomerCard>
  ) : (
    <OrdersHistoryNoOrders />
  );
}

OrdersHistory.propTypes = {
  limit: PropTypes.bool,
};

export default OrdersHistory;
