import valid from 'card-validator';
import * as Yup from 'yup';

const POSTAL_CODE_ERROR = 'Postal code is required';
const EXPIRATION_DATE_REGEX = /(0[1-9]|10|11|12)\/[0-9]{2}$/;
const CVV_REGEX = /^[0-9]{3,4}$/;

function schema() {
  return Yup.object({
    cartId: Yup.string().nullable(true),
    deliveryMethod: Yup.string().required('Delivery method is required'),
    creditCard: Yup.object().shape({
      nameOnCard: Yup.string().required('Name on card is required'),
      cardNumber: Yup.string()
        .test(
          'test-number',
          'Credit Card number is invalid',
          (value) => valid.number(value).isValid,
        )
        .min(13, 'Credit Card number must be between 13 and 19 digits')
        .max(19, 'Credit Card number must be between 13 and 19 digits')
        .required('Card number is required'),
      expirationDate: Yup.string()
        .test(
          'test-expiration-date',
          'Expiration Date is invalid',
          (value) => valid.expirationDate(value).isValid,
        )
        .matches(EXPIRATION_DATE_REGEX, 'Must be in the format MM/YY')
        .required('Expiration Date is Required'),
      cvv: Yup.string()
        .test(
          'test-cvv',
          'CVV number is invalid',
          (value) => valid.cvv(value, [3, 4]).isValid,
        )
        .matches(CVV_REGEX, 'CVV Must only be 3 or 4 digits')
        .required('CVV Code is Required'),
    }),
    shippingAddress: Yup.object().shape({
      id: Yup.string().nullable(true),
      address: Yup.string().required('Address is required'),
      address2: Yup.string().nullable(true),
      city: Yup.string().required('City is required'),
      region: Yup.string().required('Region / state is required'),
      postalCode: Yup.string().required(POSTAL_CODE_ERROR),
      country: Yup.string().required('Country is required'),
    }),
    billingAddress: Yup.object().shape({
      id: Yup.string().nullable(true),
      address: Yup.string().required('Address is required'),
      address2: Yup.string().nullable(true),
      city: Yup.string().required('City is required'),
      region: Yup.string().required('Region / state is required'),
      postalCode: Yup.string().required(POSTAL_CODE_ERROR),
      country: Yup.string().required('Country is required'),
    }),
  });
}

export default schema;
