import { useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../../../transforms';
import { FieldContainer } from '../FieldContainer';

function LanguageSelect({
  label = null,
  style = {},
  className = '',
  required = false,
  hideLabel = false,
}) {
  const { locale, setLocale } = useLocale();
  const { t } = useTranslation('forms');

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { onChange, onBlur, ref, name } = register('language');

  const languages = languageOptions[locale];

  function handleChange(e) {
    setLocale(e.target.value);
    onChange(e);
  }

  return (
    <FieldContainer
      style={style}
      className={className}
      required={required}
      hideLabel={hideLabel}
      name={name}
      label={label}
    >
      <select
        ref={ref}
        onBlur={onBlur}
        name={name}
        onChange={handleChange}
        className={`block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${
          errors?.[name]
            ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
            : 'ring-gray-300 placeholder:text-gray-400 focus:ring-rcprimary-400'
        }`}
        value={!locale ? `${t('Select a Language')}...` : locale}
        disabled={!languages}
      >
        <option disabled>{`${t('Select a Language')}...`}</option>
        {languages?.map((l) => (
          <option key={l.value} value={l.value}>
            {l.text}
          </option>
        ))}
      </select>
    </FieldContainer>
  );
}

LanguageSelect.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

export default LanguageSelect;
