import * as Yup from 'yup';

const PASSWORD_REGEX =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
const YEAR_REGEX = /(19|20)\d{2}/;

function schema() {
  return Yup.object().shape({
    id: Yup.string().nullable(true),
    partnerLocationId: Yup.string().nullable(true),
    userAttributes: Yup.object().shape({
      firstName: Yup.string().required('first name is required'),
      lastName: Yup.string().required('last name is required'),
      email: Yup.string()
        .email('email must be a valid format')
        .required('email is required'),
      phone: Yup.string().required('phone number is required'),
      password: Yup.string()
        .required('Password is required')
        .matches(
          PASSWORD_REGEX,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character',
        ),
      acceptTerms: Yup.boolean()
        .required('You must accept the terms and conditions')
        .oneOf([true], 'The terms and conditions must be accepted.'),
      acceptSms: Yup.boolean().required('You must accept the SMS terms'),
      acceptMarketing: Yup.boolean().required(
        'You must accept the marketing terms',
      ),
    }),
    accountAddressesAttributes: Yup.array().of(
      Yup.object().shape({
        address: Yup.string().required('address is required'),
        address2: Yup.string().nullable(true),
        city: Yup.string().required('city is required'),
        region: Yup.string().required('region / state is required'),
        postalCode: Yup.string().required('postal code is required'),
        country: Yup.string().required('country is required'),
        isShipping: Yup.boolean().required('is shipping is required'),
        isDefault: Yup.boolean().required('is default is required'),
      }),
    ),
    patientAttributes: Yup.object({
      firstName: Yup.string().required('patient first name is required'),
      lastName: Yup.string().required('patient last name is required'),
      birthDay: Yup.string().required('day of birth is required'),
      birthMonth: Yup.string().required('birth month is required'),
      birthYear: Yup.string()
        .required('birth year is required')
        .matches(YEAR_REGEX, 'year must be between 1900 and 2099'),
    }),
  });
}

export default schema;
