import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@retainerclub/hooks';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { profileNavigation } from '../helpers/navLinks';

const ACTIVE_TEXT_COLOR = 'text-rcprimary-400';
const DEFAULT_TEXT_COLOR = 'text-white';
const LINK_CLASSES =
  'group flex w-full items-center justify-start gap-x-2.5 px-4 py-2 text-left text-sm';
const ICON_CLASSES =
  'group-hover:text-rcprimary-400 h-5 w-5 flex-none text-white/60';

function DesktopNavProfile() {
  const { currentUser, signOut } = useAuth();
  const { t } = useTranslation('navigation');

  return currentUser ? (
    <Menu as="div" className="relative shrink-0">
      <div>
        <MenuButton className="flex max-w-xs items-center rounded-xl text-black text-sm hover:text-rcprimary-400 focus:outline-none">
          {currentUser.imageUrl !== null &&
          currentUser.imageUrl !== undefined &&
          currentUser.imageUrl !== '' ? (
            <img
              className="mr-2 inline-block size-8 rounded-full"
              src={currentUser.imageUrl}
              alt="Profile"
            />
          ) : (
            <UserCircleIcon className="mr-2 size-8" aria-hidden="true" />
          )}
        </MenuButton>
      </div>
      <Transition
        as="div"
        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md border border-rcprimary-100/30 bg-black/90 py-1 shadow-lg ring-1 ring-black/5 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[enter]:scale-100 data-[leave]:scale-95 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
      >
        <MenuItems>
          {profileNavigation.map((item) => (
            <MenuItem key={item.name}>
              {({ active }) => (
                <NavLink
                  to={item.to}
                  className={`${
                    active ? ACTIVE_TEXT_COLOR : DEFAULT_TEXT_COLOR
                  } ${LINK_CLASSES}`}
                >
                  {item.icon && (
                    <item.icon className={ICON_CLASSES} aria-hidden="true" />
                  )}
                  {t(item.name)}
                </NavLink>
              )}
            </MenuItem>
          ))}

          <MenuItem>
            {({ active }) => (
              <button
                type="button"
                onClick={signOut}
                className={`${
                  active ? ACTIVE_TEXT_COLOR : DEFAULT_TEXT_COLOR
                } ${LINK_CLASSES}`}
              >
                <LockClosedIcon className={ICON_CLASSES} aria-hidden="true" />
                {t('Sign Out')}
              </button>
            )}
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  ) : (
    <NavLink
      to="/signin"
      className="flex items-center font-semibold text-gray-900 text-sm leading-6"
    >
      <UserCircleIcon className="mr-2 size-8" aria-hidden="true" />
      <span className="font-normal text-base text-rcprimary-400">Sign In</span>
    </NavLink>
  );
}

export default DesktopNavProfile;
