/* eslint-disable react/jsx-props-no-spreading */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApp, useAuth, useToast } from '@retainerclub/hooks';
import { UPDATE_PROFILE } from '@retainerclub/shared-api';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  FileDropzone,
  Input,
  LanguageSelect,
  PhoneInput,
  SubmitButton,
} from '../../../shared';
import { initialValues, schema } from './helpers';

function BasicProfile() {
  const { currentUser, updateCurrentUser } = useAuth();
  const { setToast } = useToast();
  const { t } = useTranslation('ui');

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {
      isLoading,
      isSubmitting,
      isSubmitSuccessful,
      isDirty,
      isValid,
    },
    formState,
    getValues,
    setValue,

    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ currentUser }),
    mode: 'onBlur',
  });

  const [update, { data, error }] = useMutation(UPDATE_PROFILE);
  const { isAdmin } = useApp();

  useEffect(() => {
    if (isSubmitSuccessful && (error === null || error === undefined)) {
      reset(data?.updateProfile?.currentUser);
      setToast({
        show: true,
        title: 'Success',
        message: 'Your profile has been updated',
        success: true,
      });
    }
  }, [
    data?.updateProfile?.currentUser,
    error,
    isSubmitSuccessful,
    reset,
    setToast,
  ]);

  async function updateProfile(values) {
    try {
      const variables = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      await update(variables);
      await updateCurrentUser(true);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form onSubmit={handleSubmit(updateProfile)} noValidate>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-black/10 border-b pb-12 md:grid-cols-3">
          <div>
            <h2 className="font-semibold text-base text-black leading-7">
              {t('Personal Information')}
            </h2>
            <p className="mt-1 text-black/70 text-sm leading-6">
              {t('Update your profile information')}.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <Input
              className="sm:col-span-3"
              required
              name="firstName"
              label="First Name"
            />

            <Input
              className="sm:col-span-3"
              required
              name="lastName"
              label="Last Name"
            />

            <PhoneInput
              className="sm:col-span-3"
              extraCountries={isAdmin ? ['HN'] : []}
              required
            />

            <LanguageSelect className="sm:col-span-3" required />

            <FileDropzone
              className="sm:col-span-full"
              label="Photo"
              required
              name="imageUrl"
              defaultImage="https://cdn.retainerclub.com/images/defaults/default-user.jpeg"
              storagePath="images/avatars"
            />

            {error && (
              <div className="sm:col-span-6">
                <ErrorMessage error={error} />
              </div>
            )}

            <div className="sm:col-span-6">
              <div className="flex items-center justify-end">
                <SubmitButton
                  isLoading={isLoading}
                  isSubmitting={isSubmitting}
                  isDirty={isDirty}
                  isValid={isValid}
                  label="Update Profile"
                  full={false}
                  notRounded
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default BasicProfile;
