import PropTypes from 'prop-types';
import dobFormatter from '../../../transforms/dobFormatter';
import Badge from '../../Badge/Badge';
import Button from '../../Button/Button';
import RcLink from '../../RcLink/RcLink';
import ShowTableStandardRow from '../ShowTableStandard/ShowTableStandardRow';

function ShowPatientsRow({ patient }) {
  return (
    <ShowTableStandardRow key={patient.id}>
      <td className="max-w-0 p-4 capitalize">
        <RcLink to={`/customers/patients/${patient.slug}`}>
          {patient.firstName} {patient.lastName}
        </RcLink>
      </td>
      <td className="hidden p-4 sm:table-cell">{dobFormatter(patient.dob)}</td>
      <td className="hidden p-4 capitalize sm:table-cell">{patient.arches}</td>
      <td className="hidden p-4 capitalize sm:table-cell">
        <Badge
          withDot={false}
          name={patient.includeEot ? 'Yes' : 'No'}
          color={patient.includeEot ? 'green' : 'yellow'}
        />
      </td>
      <td className="hidden p-4 capitalize sm:table-cell">
        <Badge
          withDot={false}
          name={patient.sendToClinic ? 'Yes' : 'No'}
          color={patient.sendToClinic ? 'green' : 'yellow'}
        />
      </td>
      <td className="hidden p-4 capitalize sm:table-cell">
        <Badge
          withDot={false}
          name={patient.scanReady ? 'Yes' : 'No'}
          color={patient.scanReady ? 'green' : 'yellow'}
        />
      </td>

      <td className="flex justify-end gap-x-4 p-4 text-right">
        <Button to={`/customers/patients/${patient.slug}`} show size="xs" />
        <Button
          to={`/customers/patients/${patient.slug}/edit`}
          edit
          size="xs"
        />
      </td>
    </ShowTableStandardRow>
  );
}

ShowPatientsRow.propTypes = {
  patient: PropTypes.object.isRequired,
};

export default ShowPatientsRow;
