import PropTypes from 'prop-types';

function Logo({
  height = 32,
  width = 105,
  style = {},
  color = 'white',
  className = '',
}) {
  return (
    <svg
      viewBox="0 0 125 38"
      height={height}
      width={width}
      preserveAspectRatio="xMidYMid meet"
      style={style}
      className={`${
        color === 'white' ? 'fill-white hover:fill-rcprimary-400' : 'fill-black'
      } ${className}`}
      aria-label="Retainer Club Logo"
      role="img"
    >
      <g>
        <g>
          <path d="M9.3,25l-1,2.6c0,0-0.5-0.3-1.3-0.3c-1.7,0-2.5,1.5-2.5,1.5v7.3H1.6V24.9h2.7v1.6c0,0,1-1.9,3.1-1.9C8.4,24.6,9.3,25,9.3,25z" />
          <path d="M20.5,30.4c0,0.2,0,0.6,0,1.1h-8.4c0.2,1,1.4,2.3,3.1,2.3c2,0,3.1-1.1,3.1-1.1l1.4,2.1c0,0-1.8,1.6-4.5,1.6c-3.4,0-6-2.6-6-5.9s2.4-5.9,5.6-5.9C18,24.6,20.5,27,20.5,30.4z M17.7,29.5c0-1-0.9-2.3-2.8-2.3c-1.8,0-2.7,1.3-2.7,2.3H17.7z" />
          <path d="M21.3,24.9h2.2v-3.3h2.8v3.3h3.4v2.6h-3.4V32c0,1.4,0.7,1.8,1.5,1.8c0.6,0,1.2-0.4,1.2-0.4l1,2.3c0,0-1.1,0.7-2.7,0.7c-2.2,0-3.8-1.6-3.8-4v-5h-2.2V24.9z" />
          <path d="M41.1,28.8v7.3h-2.6v-1.4c0,0-1.2,1.7-3.7,1.7c-2.1,0-4-1.4-4-3.8c0-2.2,2-3.7,4.4-3.7c2.1,0,3.1,0.8,3.1,0.8v-0.6c0-1.5-1.4-1.9-2.4-1.9c-1.6,0-3.4,0.8-3.4,0.8l-0.9-2.3c0,0,2.1-1,4.4-1C39.2,24.6,41.1,26.2,41.1,28.8z M38.3,32.7v-1.2c0,0-1-0.6-2.6-0.6c-0.9,0-2.1,0.4-2.1,1.6s1.2,1.4,2,1.4C37.4,34,38.3,32.7,38.3,32.7z" />
          <path d="M45.2,19.4c1,0,1.9,0.8,1.9,1.9s-0.8,1.9-1.9,1.9s-1.9-0.8-1.9-1.9S44.1,19.4,45.2,19.4z M43.8,24.9h2.8v11.2h-2.8V24.9z" />
          <path d="M59.4,28.8v7.3h-2.8v-6.6c0-1.6-1-2.3-2.2-2.3c-1.5,0-2.2,1.4-2.2,1.4V36h-2.8V24.9h2.7v1.6c0,0,1-1.9,3.4-1.9C57.5,24.6,59.4,26.1,59.4,28.8z" />
          <path d="M72.5,30.4c0,0.2,0,0.6,0,1.1h-8.4c0.2,1,1.4,2.3,3.1,2.3c2,0,3.1-1.1,3.1-1.1l1.4,2.1c0,0-1.8,1.6-4.5,1.6c-3.4,0-6-2.6-6-5.9s2.4-5.9,5.6-5.9C69.9,24.6,72.5,27,72.5,30.4z M69.6,29.5c0-1-0.9-2.3-2.8-2.3c-1.8,0-2.7,1.3-2.7,2.3H69.6z" />
          <path d="M82,25l-1,2.6c0,0-0.5-0.3-1.3-0.3c-1.7,0-2.5,1.5-2.5,1.5v7.3h-2.8V24.9h2.7v1.6c0,0,1-1.9,3.1-1.9C81.1,24.6,82,25,82,25z" />
        </g>
        <g>
          <path d="M95.4,34.3c0,0-1.6,2.1-4.5,2.1c-3.2,0-5.6-2.5-5.6-5.7s2.4-5.7,5.6-5.7c2.8,0,4.4,2,4.4,2L94,28.2c0,0-1.1-1.3-3-1.3c-2.2,0-3.6,1.8-3.6,3.9s1.4,3.9,3.6,3.9c2.1,0,3.2-1.6,3.2-1.6L95.4,34.3z" />
          <path d="M96.6,18.2h2v17.9h-2V18.2z" />
          <path d="M100.9,32.2v-7h2v6.7c0,1.9,1.2,2.7,2.6,2.7c1.8,0,2.7-1.8,2.7-1.8v-7.6h2v10.9h-1.8v-1.7c0,0-1,2-3.4,2C102.8,36.4,100.9,34.9,100.9,32.2z" />
          <path d="M123.4,30.6c0,3.2-2.3,5.7-5.3,5.7c-2.6,0-3.7-1.9-3.7-1.9V36h-1.8V18.2h2v8.3c0,0,1.1-1.6,3.5-1.6C121.1,24.9,123.4,27.4,123.4,30.6z M114.6,32.6c0,0,1,1.9,3.2,1.9s3.6-1.8,3.6-3.9s-1.4-3.9-3.6-3.9s-3.2,1.9-3.2,1.9V32.6z" />
        </g>
      </g>
      <g className="fill-rcprimary-400">
        <path d="M61.9,11.4C49.5,11.4,39,5.2,38.6,5c-0.9-0.5-1.2-1.6-0.6-2.5c0.5-0.9,1.6-1.2,2.5-0.6C40.6,2,50.6,7.8,62,7.8c11.6,0,21.4-5.8,21.4-5.9c0.9-0.5,2-0.2,2.5,0.6s0.2,2-0.6,2.5C84.8,5.2,74.5,11.4,61.9,11.4z" />
      </g>
    </svg>
  );
}

Logo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
