import PropTypes from 'prop-types';
import Card from '../../Card/Card';
import ShowTableStandard from '../ShowTableStandard/ShowTableStandard';
import ShowTableStandardRow from '../ShowTableStandard/ShowTableStandardRow';
import ShowPatientsRow from './ShowPatientsRow';
function ShowPatients({ patients = [], title = 'Patients', className = '' }) {
  return (
    <Card className={`p-4 ${className}`} heading={title}>
      <ShowTableStandard
        tHead={
          <>
            <th scope="col" className="p-4">
              Name
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              DOB
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Arches
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              EOT Included?
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Ship First Set To Clinic?
            </th>
            <th scope="col" className="hidden p-4 sm:table-cell">
              Scan Ready?
            </th>
            <th scope="col" className="p-4 text-right">
              Actions
            </th>
          </>
        }
        tBody={
          patients.length > 0 ? (
            patients.map((patient) => (
              <ShowPatientsRow key={patient.id} patient={patient} />
            ))
          ) : (
            <ShowTableStandardRow>
              <td className="p-4 text-center" colSpan="7">
                No patients found
              </td>
            </ShowTableStandardRow>
          )
        }
      />
    </Card>
  );
}

ShowPatients.propTypes = {
  patients: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default ShowPatients;
