import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { usePolicy } from '@retainerclub/hooks';
import { useRef } from 'react';
import Policy from './Policy';

function LegalModal() {
  const cancelButtonRef = useRef(null);
  const { policyModalOpen, setPolicyModalOpen, policy } = usePolicy();

  return (
    <Transition show={policyModalOpen}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setPolicyModalOpen(false)}
      >
        <TransitionChild
          as="div"
          className="fixed inset-0 bg-gray-500/75 transition-opacity duration-300 ease-out data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
        />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="div"
              className="relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300 ease-out data-[closed]:translate-y-4 data-[enter]:translate-y-0 data-[leave]:translate-y-4 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0 sm:my-8 sm:w-full sm:max-w-3xl data-[closed]:sm:translate-y-0 data-[leave]:sm:translate-y-0 data-[closed]:sm:scale-95 data-[enter]:sm:scale-100 data-[leave]:sm:scale-95"
            >
              <DialogPanel>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="max-h-96 space-y-4 overflow-auto pr-4 sm:mt-0">
                      <Policy policy={policy} />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 text-sm shadow-sm ring-1 ring-gray-300 ring-inset hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setPolicyModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LegalModal;
