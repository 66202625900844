import { PopoverButton } from '@headlessui/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function NavColumn(props) {
  const { title, links } = props;

  return (
    <div>
      <h3 className="font-oswald text-md text-rcprimary-300 leading-6">
        {title}
      </h3>
      <div className="mt-6 flow-root">
        <div className="-my-2">
          {links.map((item) => (
            <PopoverButton
              as={NavLink}
              key={item.name}
              to={item.to}
              className="group flex gap-x-4 py-2 font-semibold text-sm text-white leading-6 hover:text-rcprimary-400"
            >
              <item.icon
                className="h-6 w-6 flex-none text-white/60 group-hover:text-rcprimary-400"
                aria-hidden="true"
              />
              {item.name}
            </PopoverButton>
          ))}
        </div>
      </div>
    </div>
  );
}

NavColumn.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
};

export default NavColumn;
