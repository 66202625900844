import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import LoadingOrError from '../../Loader/LoadingOrError';

function ShowTable({
  partnerOrganizationId = null,
  partnerLocationId = null,
  dataTable: DataTable,
  query,
  loadingTitle,
  newTo,
  hideOrg = false,
  hideLoc = false,
}) {
  let variables = {};
  let linkParams = '';

  if (partnerOrganizationId) {
    variables = { partnerOrganizationId };
    linkParams = `?partnerOrganizationId=${partnerOrganizationId}`;
  }

  if (partnerLocationId) {
    variables = { partnerLocationId };
    linkParams = `?partnerOrganizationId=${partnerOrganizationId}&partnerLocationId=${partnerLocationId}`;
  }

  const { error, loading, data } = useQuery(query, {
    variables,
  });

  return (
    <div className="flex flex-col gap-x-8">
      {loading || error ? (
        <LoadingOrError error={error} loading={loading} title={loadingTitle} />
      ) : (
        <DataTable
          data={data}
          height={500}
          newTo={`${newTo}${linkParams}`}
          hideLoc={hideLoc}
          hideOrg={hideOrg}
        />
      )}
    </div>
  );
}

ShowTable.propTypes = {
  partnerOrganizationId: PropTypes.string,
  partnerLocationId: PropTypes.string,
  dataTable: PropTypes.elementType.isRequired,
  query: PropTypes.object.isRequired,
  loadingTitle: PropTypes.string.isRequired,
  newTo: PropTypes.string.isRequired,
  hideLoc: PropTypes.bool,
  hideOrg: PropTypes.bool,
};

export default ShowTable;
