import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { UPDATE_LAB_NOTE } from '@retainerclub/partner-api';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseLoader, ErrorMessage } from '../../../../shared';
import ReferralsProductionListModalFormSubmit from './ReferralsProductionListModalFormSubmit';
import { initialValues, schema } from './helpers';

function ReferralsProductionListModalFormInner({
  record,
  handleCloseModal,
  children,
}) {
  const [mutationErrors, setMutationErrors] = useState(null);

  const [update, { error, loading }] = useMutation(UPDATE_LAB_NOTE);

  const {
    register,
    handleSubmit,
    formState: { isLoading, isSubmitting, isValid },
    formState,
    getValues,
    setValue,
    control,
    ...rest
  } = useForm({
    resolver: yupResolver(schema()),
    defaultValues: initialValues({ record }),
    values: initialValues({ record }),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (record && !record?.settingsOverride) {
      setValue(
        'settingGroupOptionIds',
        record.partnerLocation.settingGroupOptionIds,
      );
    }
  }, [record, setValue]);

  async function submitRecord(values) {
    try {
      const params = {
        variables: {
          input: {
            input: { ...values },
            clientMutationId: crypto.randomUUID(),
          },
        },
      };

      const { data } = await update(params);

      const mErrors = data.updateLabNote.errorMessages;

      if (mErrors.length > 0) {
        setMutationErrors(mErrors);
      } else {
        setMutationErrors(null);
        handleCloseModal();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  if (loading) {
    return <BaseLoader title="Lab Notes" />;
  }

  return (
    <FormProvider
      {...rest}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
    >
      <form onSubmit={handleSubmit(submitRecord)} noValidate>
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
          <div className="col-span-6 grid grid-cols-1 gap-4 sm:grid-cols-6 md:col-span-6">
            {children}

            <div className="col-span-full">
              {error && (
                <div className="col-span-full mt-4">
                  <ErrorMessage error={error} />
                </div>
              )}

              {mutationErrors && (
                <div className="col-span-full mt-4">
                  <ErrorMessage errorMessages={mutationErrors} />
                </div>
              )}
              <ReferralsProductionListModalFormSubmit
                loading={loading}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                isValid={isValid}
                partnerLocationId={record.partnerLocationId}
                handleSubmit={handleSubmit(submitRecord)}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

ReferralsProductionListModalFormInner.propTypes = {
  record: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ReferralsProductionListModalFormInner;
