import { gql } from '@apollo/client';

const ProductionOrderInfo = gql`
  fragment ProductionOrderInfo on ProductionOrder {
    id
    state
    hexCode
    slug
    arches
    upperArchQuantity
    lowerArchQuantity
    productionNotes
    address
    patientSettings
    sendToClinic
    manufacturerActivities {
      id
      status
      eventType
      message
      createdAt
    }
    account {
      id
      accountNumber
    }
    patient {
      id
      slug
      firstName
      lastName
      dob
      birthDay
      birthMonth
      birthYear
    }
    order {
      id
      hexCode
    }
    partnerLocation {
      id
      name
      slug
    }
    referral {
      id
      hexCode
      slug
      source
      state
      sentAt
      consumedAt
      createdAt
    }
    user {
      id
      firstName
      lastName
      slug
      email
      phone
    }
    productionOrderItemsAttributes {
      id
      name
      quantity
      trackingNumber
      manufacturerExternalId
      shippingCarrier {
        id
        name
      }
      shippingCarrierId
      product {
        id
        name
        slug
        featuredImage
      }
      patient {
        id
        slug
        firstName
        lastName
        dob
      }
    }
    createdAt
    updatedAt
  }
`;

export default ProductionOrderInfo;
