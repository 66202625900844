import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@retainerclub/common';
import { useAccount, useCart, useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';

function ShopProductsModal({ priceBookProduct = null, open, setOpen }) {
  const { locale } = useLocale();
  const {
    updateCart,
    cartId,
    cart: { cartItemsAttributes },
  } = useCart();
  const { currentPatient } = useAccount();

  function handleAddOrRemoveToCart() {
    updateCart({
      id: cartId,
      patientId: currentPatient.id,
      cartItemsAttributes: [
        ...cartItemsAttributes.map((item) => ({
          productId: item.product.id,
          quantity: item.quantity,
          patientId: item.patientId,
          id: item.id,
        })),
        {
          productId: priceBookProduct.product.id,
          quantity: 1,
          patientId: currentPatient.id,
        },
      ],
    });
    setOpen(false);
  }

  return (
    <Transition show={open}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <TransitionChild
          as="div"
          className="fixed inset-0 hidden bg-gray-50/75 transition-opacity duration-300 ease-out data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0 md:block"
        >
          <div className="fixed inset-0 hidden bg-gray-50/75 transition-opacity md:block" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <TransitionChild
              as="div"
              className="flex w-full text-left text-base transition duration-300 ease-out data-[closed]:translate-y-4 data-[enter]:translate-y-0 data-[leave]:translate-y-4 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0 md:my-8 md:max-w-2xl md:px-4 data-[closed]:md:translate-y-0 data-[leave]:md:translate-y-0 data-[closed]:md:scale-95 data-[enter]:md:scale-100 data-[leave]:md:scale-95 lg:max-w-4xl"
            >
              <DialogPanel className="flex w-full text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <button
                    type="button"
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="size-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                    <div className="sm:col-span-4 lg:col-span-5">
                      <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                        <img
                          src={priceBookProduct.product.featuredImage}
                          alt={priceBookProduct.product.name}
                          className="object-cover object-center"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-8 lg:col-span-7">
                      <h2 className="font-bold text-2xl text-gray-900 sm:pr-12">
                        {priceBookProduct.product.name}
                      </h2>

                      <section
                        aria-labelledby="information-heading"
                        className="mt-3"
                      >
                        <h3 id="information-heading" className="sr-only">
                          Product information
                        </h3>

                        <p className="text-2xl text-gray-900">
                          {(
                            (priceBookProduct.overPrice
                              ? priceBookProduct.price
                              : priceBookProduct.product.price) / 100
                          ).toLocaleString(locale, {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </p>

                        <div className="mt-6">
                          <p className="text-gray-700 text-sm">
                            {priceBookProduct.product.description}
                          </p>
                          <ul className="mt-8 space-y-3 text-gray-600 text-sm leading-6">
                            {priceBookProduct.product.productFeatures.map(
                              (feature) => (
                                <li key={feature.id} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-rcprimary-400"
                                    aria-hidden="true"
                                  />
                                  {feature.text}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </section>

                      <section
                        aria-labelledby="options-heading"
                        className="mt-6"
                      >
                        <form>
                          <div className="mt-6">
                            <Button
                              type="button"
                              name="Add to Cart"
                              size="lg"
                              className="w-full justify-center text-center"
                              onClick={() => handleAddOrRemoveToCart()}
                            />
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

ShopProductsModal.propTypes = {
  priceBookProduct: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ShopProductsModal;
