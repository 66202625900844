import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

function FormAdminSidebar({
  customSidebar = null,
  sidebarTitle = null,
  sidebarDescription = null,
}) {
  const { isAdmin } = useApp();
  const { pathname } = useLocation();

  return isAdmin ? (
    <div className="hidden sm:block">
      {customSidebar || (
        <>
          {sidebarTitle && (
            <h2 className="font-bold text-black text-xl leading-6">
              {pathname.endsWith('/new') ? 'New ' : 'Edit '}
              {sidebarTitle}
            </h2>
          )}
          {sidebarDescription && (
            <p className="mt-1 text-black/40 text-sm">{sidebarDescription}</p>
          )}
        </>
      )}
    </div>
  ) : null;
}

FormAdminSidebar.propTypes = {
  customSidebar: PropTypes.node,
  sidebarDescription: PropTypes.string,
  sidebarTitle: PropTypes.string,
};

export default FormAdminSidebar;
