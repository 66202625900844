import { TrashIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function DeleteButton({ onClick, size = 'md' }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="rounded-full bg-red-600 p-2 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-600 focus-visible:outline-offset-2"
    >
      <TrashIcon
        className={`${size === 'sm' ? 'size-4' : ''} ${
          size === 'md' ? 'size-5' : ''
        } ${size === 'md' ? 'size-6' : ''}`}
        aria-hidden="true"
      />
    </button>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default DeleteButton;
