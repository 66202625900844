import { AddressAutofill } from '@mapbox/search-js-react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../FormElements/Inputs/Input';
import CountrySelect from '../../../FormElements/Selects/CountrySelect';
import RegionSelect from '../../../FormElements/Selects/RegionSelect';

function AddressFields() {
  const { setValue } = useFormContext();

  return (
    <div>
      <div>
        <AddressAutofill
          accessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          confirmOnBrowserAutofill={{
            minimap: false,
            skipConfirmModal: (feature) =>
              ['exact', 'high'].includes(
                feature.properties.match_code.confidence,
              ),
          }}
          onRetrieve={(address) => {
            if (address?.features?.length > 0) {
              const {
                country_code: countryCode,
                region_code: regionCode,
                matching_name: matchingName,
              } = address.features[0].properties;
              setValue('country', countryCode.toUpperCase());
              setValue('region', regionCode.toUpperCase());
              setValue('address', matchingName);
            }
          }}
          onChange={(event) => {
            setValue('address', event);
          }}
          options={{
            country: ['US'],
          }}
        >
          <Input name="address" label="Address" required autoComplete="" />
        </AddressAutofill>
      </div>
      <div className="mt-6 translate-y-1 text-red-700 text-xs sm:col-span-6">
        Please add Unit, Suite, or Apt number to avoid shipping delays.
      </div>
      <div>
        <Input
          name="address2"
          label="Address Line 2"
          autoComplete="address-line2"
        />
      </div>
      <div>
        <Input
          name="city"
          label="City"
          required
          autoComplete="address-level2"
        />
      </div>
      <div>
        <CountrySelect name="country" required autoComplete="" usOnly />
      </div>
      <div>
        <RegionSelect
          name="region"
          countryFieldName="country"
          autoComplete=""
        />
      </div>
      <div>
        <Input
          name="postalCode"
          label="Postal Code"
          required
          autoComplete="postal-code"
        />
      </div>
    </div>
  );
}

export default AddressFields;
