import { useApp } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

function FormSidebar({ sidebarTitle = null, sidebarDescription = null }) {
  const { isPartner } = useApp();
  const { pathname } = useLocation();

  return isPartner && (sidebarDescription || sidebarTitle) ? (
    <div className="mb-2 flex flex-col items-start rounded-md bg-white p-4 shadow sm:flex-row sm:justify-between">
      {sidebarTitle && (
        <h2 className="mr-4 font-bold text-black/90 text-xl leading-6">
          {pathname.endsWith('/new') ? 'New ' : 'Edit '}
          {sidebarTitle}
        </h2>
      )}
      {sidebarDescription && (
        <p className="mt-1 text-black/50 text-sm">{sidebarDescription}</p>
      )}
    </div>
  ) : null;
}

FormSidebar.propTypes = {
  sidebarTitle: PropTypes.string,
  sidebarDescription: PropTypes.string,
};

export default FormSidebar;
