/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLocale } from '@retainerclub/hooks';
import { useTranslation } from 'react-i18next';
import { languageOptions } from '../../../transforms';

function LanguageSelectAuth() {
  const { locale, setLocale } = useLocale();
  const { t } = useTranslation('forms');

  const languages = languageOptions[locale];

  function handleChange(e) {
    setLocale(e.target.value);
  }

  return (
    <div>
      <label htmlFor="language" className="sr-only">
        Language
      </label>
      <select
        id="language"
        name="language"
        onChange={handleChange}
        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-gray-300 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-rcprimary-400 focus:ring-inset sm:text-sm sm:leading-6"
        value={!locale ? `${t('Select a Language')}...` : locale}
        disabled={!languages}
      >
        <option disabled>{`${t('Select a Language')}...`}</option>
        {languages?.map((l) => (
          <option key={l.value} value={l.value}>
            {l.text}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LanguageSelectAuth;
