const findDefaultAddress = (addresses, type) =>
  addresses.find((address) => address.isDefault && address[`is${type}`]) || {};

const createAddressObject = (defaultAddress) => ({
  id: defaultAddress.id || null,
  address: defaultAddress.address || '',
  address2: defaultAddress.address2 || '',
  city: defaultAddress.city || '',
  country: defaultAddress.country || 'US',
  region: defaultAddress.region || '',
  postalCode: defaultAddress.postalCode || '',
});

function initialValues({ account = {}, user = {}, cartId }) {
  const { accountAddresses = [] } = account;

  const defaultShipping = findDefaultAddress(accountAddresses, 'Shipping');
  const defaultBilling = findDefaultAddress(accountAddresses, 'Billing');

  return {
    cartId,
    deliveryMethod: 'standard',
    creditCard: {
      nameOnCard: `${user?.firstName} ${user?.lastName}`,
      cardNumber: '',
      expirationDate: '',
      cvv: '',
    },
    billingAddress: createAddressObject(defaultBilling),
    shippingAddress: createAddressObject(defaultShipping),
  };
}

export default initialValues;
