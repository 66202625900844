import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/20/solid';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Button } from '@retainerclub/common';
import { useAuth, useCart } from '@retainerclub/hooks';

function DesktopNavCart() {
  const { cart, destroyCartItem } = useCart();
  const { currentUser } = useAuth();

  function handleRemoveItem(id) {
    destroyCartItem(id);
  }

  const { cartItemsAttributes = [] } =
    cart !== null && cart !== undefined ? cart : {};

  return currentUser ? (
    <Popover className="z-50 flow-root text-sm lg:relative">
      <PopoverButton className="group -m-2 flex items-center p-2 outline-none ring-0">
        <ShoppingBagIcon
          className="size-6 shrink-0 text-black group-hover:text-rcprimary-400"
          aria-hidden="true"
        />
        <span className="ml-1 font-medium text-rcgray-700 text-sm group-hover:text-rcgray-800">
          {cartItemsAttributes.length}
        </span>
      </PopoverButton>
      <Transition
        as="div"
        className="lg:-mr-1.5 absolute inset-x-0 top-16 mt-px bg-white pb-6 shadow-lg transition duration-200 ease-out data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0 sm:px-2 lg:top-full lg:right-0 lg:left-auto lg:mt-3 lg:w-96 lg:rounded-lg lg:ring-1 lg:ring-black/5"
      >
        <PopoverPanel>
          <h2 className="sr-only">Shopping Cart</h2>

          <div className="mx-auto max-w-3xl px-4">
            <ul className="divide-y divide-rcgray-300">
              {cartItemsAttributes.length > 0 ? (
                cartItemsAttributes.map((cartItem) => (
                  <li key={cartItem.id} className="flex items-center py-6">
                    <div className="size-16 shrink-0 overflow-hidden rounded-md border border-gray-200">
                      <img
                        src={cartItem?.product?.featuredImage}
                        alt={cartItem?.name}
                        className="size-full object-cover object-cente"
                      />
                    </div>
                    <div className="ml-4 flex flex-1 flex-col">
                      <div>
                        <div className="flex justify-between font-medium text-base text-gray-900">
                          <h3 className="text-sm">
                            {cartItem.name}
                            {cartItem?.patient ? (
                              <>
                                <br />
                                <span className="text-xs">
                                  Purchasing For:&nbsp;
                                  <span className="font-bold">
                                    {cartItem?.patient?.firstName}
                                  </span>
                                </span>
                              </>
                            ) : null}
                          </h3>

                          <p className="ml-4">
                            ${(cartItem.price / 100).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 items-center justify-between text-sm">
                        <p className="text-gray-500">Qty {cartItem.quantity}</p>
                        <button
                          type="button"
                          onClick={() => handleRemoveItem(cartItem.id)}
                          className="rounded-full border p-2 text-white shadow-sm hover:border-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-600 focus-visible:outline-offset-2"
                        >
                          <span className="sr-only">Remove</span>
                          <TrashIcon
                            className="size-4 text-rccallouts-danger"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li className="flex items-center py-6">
                  <div className="ml-4 flex-auto">
                    <h3 className="text-center font-medium text-gray-900">
                      No items in cart
                    </h3>
                  </div>
                </li>
              )}
            </ul>

            {cartItemsAttributes.length > 0 && (
              <Button
                to="/checkout"
                name="Checkout"
                size="lg"
                className="w-full justify-center text-center"
              />
            )}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  ) : null;
}

export default DesktopNavCart;
